import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sendPostRequest } from "../../Client";
import { useMutation } from "@tanstack/react-query";
import { useGetRequest } from "../../Client";
import StatesDropdown from "../../Components/States";

export default function UpdateAddress(props) {
  let orderID = props.orderID;

  const states = StatesDropdown();

  // updating
  const [orderName, setOrderName] = useState();
  const [orderCompany, setOrderCompany] = useState();
  const [orderAddress1, setOrderAddress1] = useState();
  const [orderAddress2, setOrderAddress2] = useState();
  const [orderCity, setOrderCity] = useState();
  const [orderState, setOrderState] = useState();
  const [orderCountry, setOrderCountry] = useState();
  const [orderZip, setOrderZip] = useState();
  const [orderPhone, setOrderPhone] = useState();
  const [orderEmail, setOrderEmail] = useState();
  const [shippedWith, setShippedWith] = useState();
  const [tracking, setTracking] = useState();

  const { status, data } = useGetRequest("orders/" + props.orderID);

  const { status: statusShippingOptions, data: dataShippingOptions } =
    useGetRequest("checkout/services/" + props.customerID, {});

  useEffect(() => {
    data.order ? setOrderName(data?.order[0]?.orderName) : setOrderName("");
    data.order
      ? setOrderCompany(data?.order[0]?.orderCompany)
      : setOrderCompany("");
    data.order
      ? setOrderAddress1(data?.order[0]?.orderAddress1)
      : setOrderAddress1("");
    data.order
      ? setOrderAddress2(data?.order[0]?.orderAddress2)
      : setOrderAddress2("");
    data.order ? setOrderCity(data?.order[0]?.orderCity) : setOrderCity("");
    data.order ? setOrderState(data?.order[0]?.orderState) : setOrderState("");
    data.order
      ? setOrderCountry(data?.order[0]?.orderCountry)
      : setOrderCountry("");
    data.order ? setOrderZip(data?.order[0]?.orderZip) : setOrderZip("");
    data.order ? setOrderPhone(data?.order[0]?.orderPhone) : setOrderPhone("");
    data.order ? setOrderEmail(data?.order[0]?.orderEmail) : setOrderEmail("");
    data.order
      ? setShippedWith(data?.order[0]?.shippedWith)
      : setShippedWith("");
    data.order ? setTracking(data?.order[0]?.tracking) : setTracking("");
  }, [data]);

  // updating address
  const updateAddress = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateAddress(data.path, { orderID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["order"] });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    doUpdateMutation({
      path: "address/update",
      body: {
        orderName: orderName,
        orderCompany: orderCompany,
        orderAddress1: orderAddress1,
        orderAddress2: orderAddress2,
        orderCity: orderCity,
        orderState: orderState,
        orderCountry: orderCountry,
        orderZip: orderZip,
        orderPhone: orderPhone,
        orderEmail: orderEmail,
        shippedWith: shippedWith,
        tracking: tracking,
        orderID: orderID,
      },
    });
    props.setOpenAddress(false);
  };

  if (statusShippingOptions == "fetched") {
    return (
      <Transition.Root show={props.openAddress} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={props.setOpenAddress}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => props.setOpenNote(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </button>
                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="">
                              <tr>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderName"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Order Name
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderName"
                                      name="orderName"
                                      value={orderName ? orderName : ""}
                                      onChange={(e) => {
                                        setOrderName(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderCompany"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Company
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderCompany"
                                      name="orderCompany"
                                      value={orderCompany ? orderCompany : ""}
                                      onChange={(e) => {
                                        setOrderCompany(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderAddress1"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Address Line 1
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderAddress1"
                                      name="orderAddress1"
                                      value={orderAddress1 ? orderAddress1 : ""}
                                      onChange={(e) => {
                                        setOrderAddress1(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderAddress2"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Address Line 2
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderAddress2"
                                      name="orderAddress2"
                                      value={orderAddress2 ? orderAddress2 : ""}
                                      onChange={(e) => {
                                        setOrderAddress2(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderCity"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    City
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderCity"
                                      name="orderCity"
                                      value={orderCity ? orderCity : ""}
                                      onChange={(e) => {
                                        setOrderCity(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                {/* <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderState"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    State
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderState"
                                      name="orderState"
                                      value={orderState ? orderState : ""}
                                      onChange={(e) => {
                                        setOrderState(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div> */}

                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderState"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    State
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <select
                                      id="state"
                                      name="state"
                                      onChange={(e) => {
                                        setOrderState(e.target.value);
                                      }}
                                      className="h-[60%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                      <option value={orderState}>
                                        {orderState}
                                      </option>
                                      {states.map((state) => (
                                        <option value={state.code}>
                                          {state.code}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderCountry"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Country
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderCountry"
                                      name="orderCountry"
                                      value={orderCountry ? orderCountry : ""}
                                      onChange={(e) => {
                                        setOrderCountry(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderZip"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Zip
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderZip"
                                      name="orderZip"
                                      value={orderZip ? orderZip : ""}
                                      onChange={(e) => {
                                        setOrderZip(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderPhone"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Phone
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderPhone"
                                      name="orderPhone"
                                      value={orderPhone ? orderPhone : ""}
                                      onChange={(e) => {
                                        setOrderPhone(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center mb-2">
                                  <label
                                    htmlFor="orderEmail"
                                    className="w-[30%] block text-sm font-medium text-gray-700"
                                  >
                                    Email
                                  </label>
                                  <div className="mt-1 ml-4 w-[60%]">
                                    <input
                                      type="text"
                                      id="orderEmail"
                                      name="orderEmail"
                                      value={orderEmail ? orderEmail : ""}
                                      onChange={(e) => {
                                        setOrderEmail(e.target.value);
                                      }}
                                      className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </tr>
                            </tbody>

                            <div className="flex flex-row items-center mb-2 border-t border-gray-200 mt-2">
                              <div className="w-[30%] mt-2">
                                <label
                                  htmlFor="shippedWith"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Service
                                </label>
                              </div>
                              <div className="w-[60%] ml-4 mt-2">
                                <select
                                  id="shippedWith"
                                  name="shippedWith"
                                  onChange={(e) =>
                                    setShippedWith(e.target.value)
                                  }
                                  className="w-[60%] h-[80%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value={props.serviceID}>
                                    {props.carrierName} - {props.service}
                                  </option>
                                  {dataShippingOptions.services.map(
                                    (service) => (
                                      <option
                                        key={
                                          service.serviceName +
                                          service.serviceID
                                        }
                                        value={service.serviceID}
                                      >
                                        {service.carrierName} -{" "}
                                        {service.serviceName}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>

                            <div className="flex flex-row items-center mb-2 mt-2">
                              <div className="w-[30%] mt-2">
                                <label
                                  htmlFor="tracking"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Tracking
                                </label>
                              </div>
                              <div className="w-[60%] ml-4 mt-2">
                                <input
                                  type="text"
                                  id="tracking"
                                  name="tracking"
                                  value={tracking ? tracking : ""}
                                  onChange={(e) => {
                                    setTracking(e.target.value);
                                  }}
                                  className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>

                            <div className="border-t border-gray-200 px-4 py-6 mt-4 sm:px-6">
                              <div className="mt-2">
                                <button
                                  type="submit"
                                  className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-40 py-4 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          </table>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
