import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sendPostRequest, useGetRequest } from "../../Client";
import { useMutation } from "@tanstack/react-query";

export default function CustomerInventoryUpdate(props) {
  let customerID = props.customerID;

  // updating
  const [chargeType, setChargeType] = useState();
  const [chargeName, setChargeName] = useState();
  const [rateValue, setRateValue] = useState();

  const { data } = useGetRequest("customer/rate/details/" + props.rateID);

  useEffect(() => {
    setChargeType(data?.data?.chargeTypeID);
    setChargeName(data?.data?.chargeName);
    setRateValue(data?.data?.rate);
  }, [data]);

  // updating inventory
  const updateRate = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateRate(data.path, { customerID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["rates"] });
    },
  });

  if (data?.data?.rate) {
    return (
      <Transition.Root show={props.editOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setEditOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => props.setOpen(false)}
                            ></button>
                          </div>
                        </div>

                        <table className="min-w-full divide-y divide-gray-300 mt-10">
                          <tbody className="divide-gray-200">
                            <tr className="min-w-1/2">
                              <td>
                                {" "}
                                <label
                                  htmlFor="chargeType"
                                  className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                >
                                  Rate
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                <input
                                  type="text"
                                  name="chargeType"
                                  id="chargeType"
                                  disabled
                                  value={chargeName ? chargeName : ""}
                                  className="bg-gray-200 block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </td>
                            </tr>

                            <tr className="min-w-1/2">
                              <td>
                                {" "}
                                <label
                                  htmlFor="rateValue"
                                  className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                >
                                  Value
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                <input
                                  type="text"
                                  name="rateValue"
                                  id="rateValue"
                                  value={rateValue ? rateValue : ""}
                                  onChange={(e) => {
                                    setRateValue(e.target.value);
                                  }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className="mt-6">
                          <a
                            onClick={async () => {
                              try {
                                await doUpdateMutation({
                                  path: "customer/rate/update",
                                  body: {
                                    rateValue: rateValue,
                                    customerRateID: props.rateID,
                                  },
                                });
                                props.setEditOpen(false);
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                            className="w-[100%] flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                          >
                            Edit Item
                          </a>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
