import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useRef, useState, React, useEffect } from "react";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../Client";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { dateFormatter } from "../config";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import * as XLSX from "xlsx";

function Billed() {
  const initialDate = new Date().toISOString().split("T")[0];
  const initialDateArray = initialDate.split("-");

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState([]);

  const [searchCustomer, setSearchCustomer] = useState(0);
  const [searchCartonFeeCheckbox, setSearchCartonFeeCheckbox] = useState(0);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const [open, setOpen] = useState(false);

  const [billingList, setBillingList] = useState([]);

  const [picking, setPicking] = useState(false);

  const { status, data } = useGetRequest("billing/list/billed", {});

  // customers dropdown
  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const { status: statusChargeTypes, data: dataChargeTypes } = useGetRequest(
    "charges",
    {}
  );

  const [inputs, setInputs] = useState({
    date:
      initialDateArray[0] +
      "-" +
      initialDateArray[1] +
      "-" +
      initialDateArray[2],
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        billingDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const billingDelete = async (id) => {
    const res = await sendPostRequest("billing/delete", {
      customerChargeID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  const handleSubmit = async (event) => {
    const requestBody = {
      quantity: inputs.quantity,
      customerID: inputs.customerID,
      chargeID: inputs.chargeID,
      date: inputs.date,
    };

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    const res = await sendPostRequest("billing/add", requestBody);
  };

  const confirmCharges = async () => {
    const res = await sendPostRequest("billing/confirm", { selectedCharges });
    setBillingList(res.list);
  };

  function toggleAll() {
    setSelectedCharges(checked || indeterminate ? [] : billingList);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const sumChargeTotal = () => {
    let total = 0;

    billingList?.forEach((a) => {
      total += Number(a.amount);
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  const downloadCharges = async () => {
    const excelData = [];

    {
      billingList?.map((item) =>
        excelData.push({
          orderNumber: item.orderNumber,
          customer: item.customerName,
          chargeType: item.chargeName,
          quantity: item.quantity,
          cartonFee: item.processingCartonFee,
          cartonQuantity: item.processingCartonQuantity,
          chargeTotal: item.amount,
          date: item.chargeDate,
          calculation: item.calculation,
        })
      );
    }

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "billed-charges.xlsx");
  };

  const fetchCustomerDashboard = async (searchCustomer) => {
    const requestBody = {
      customer: searchCustomer,
      fromDate: searchFromDate ? searchFromDate + " 00:00:00" : null,
      toDate: searchToDate ? searchToDate + " 23:59:59" : null,
      cartonFee: searchCartonFeeCheckbox,
    };

    const res = await sendPostRequest("billing/list/billed", requestBody);

    setBillingList(res.charges);
  };

  useEffect(() => {
    fetchCustomerDashboard(searchCustomer);
  }, [searchCustomer, searchCartonFeeCheckbox, searchFromDate, searchToDate]);

  useEffect(() => {
    setBillingList(data?.list);
  }, [data]);

  const [isHovered, setIsHovered] = useState(null);
  const handleMouseOver = (e) => {
    setIsHovered(e.target.id);
  };

  if (
    status == "fetched" &&
    statusCustomers == "fetched" &&
    statusChargeTypes == "fetched"
  ) {
    return (
      <>
        <div className="flex flex-row justify-start w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100 ml-80">
            <Topbar />
            {/* content */}

            <nav className="flex ml-10 mt-5 h-10" aria-label="Breadcrumb">
              <ol
                role="list"
                className="flex space-x-4 rounded-md bg-white px-6 shadow"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <a
                      href={`/billing`}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                </li>
              </ol>
            </nav>

            {billingList?.length > 0 ? (
              <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-64">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10">
                      Billed Charges
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      {billingList?.length} billed charge
                      {billingList?.length === 1 ? "" : "s"}
                    </p>
                    <div className="mt-2 text-sm text-gray-700">
                      Total : ${sumChargeTotal()}
                    </div>
                  </div>

                  <div
                    className="flex flex-row hover:cursor-pointer"
                    onClick={() => {
                      downloadCharges();
                    }}
                  >
                    {" "}
                    <div className="text-sm text-gray-700 mr-2 underline hover:text-indigo-700 hover:cursor-pointer">
                      Download Charges
                    </div>
                    <ArrowDownIcon
                      className="h-5 w-5 mr-10"
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <form onSubmit={() => fetchCustomerDashboard}>
                  <div className="border px-2 py-2 mt-5">
                    <div className="w-full flex flex-row mt-4 justify-center align-end">
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="customer"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Customer
                          </label>
                          <div className="mt-2">
                            <select
                              id="customer"
                              name="customer"
                              onChange={(e) =>
                                setSearchCustomer(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option></option>
                              {dataCustomers.customers.map((customer) => (
                                <option value={customer.customerID}>
                                  {customer.customerName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="fromDate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              From Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                onChange={(e) =>
                                  setSearchFromDate(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="toDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            To Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="toDate"
                              id="toDate"
                              onChange={(e) => setSearchToDate(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        {" "}
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="cartonFee"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Carton Fee
                          </label>
                          <div className="mt-2">
                            <input
                              type="checkbox"
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={() =>
                                setSearchCartonFeeCheckbox(
                                  !searchCartonFeeCheckbox
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="relative">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Order/Receiver #
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Customer
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Charge Type
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Charge Total
                              </th>
                              <th
                                scope="col"
                                className="w-1/8 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="w-1/8 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Created By
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {billingList.map((list) => (
                              <tr
                                key={list.customerChargeID + Math.random()}
                                className={
                                  selectedCharges.includes(list)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-900">
                                  {list.chargeName === "Receiving charge"
                                    ? list.stockMovementID
                                    : list.orderNumber
                                    ? list.orderNumber
                                    : null}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-900 underline hover:cursor-pointer">
                                  {list.customerName}
                                </td>
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover: cursor-pointer"
                                  id={list.calculation}
                                  onMouseOver={handleMouseOver}
                                  onMouseOut={() => {
                                    setIsHovered(null);
                                  }}
                                >
                                  {list.chargeName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {list.quantity}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  $
                                  {(
                                    Math.round(list.amount * 100) / 100
                                  ).toFixed(2)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {dateFormatter(list.chargeDate)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {list.firstName} {list.lastName}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-64">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10">
                      New Charges
                    </h1>
                    <p className="mt-2 mb-10 text-sm text-gray-700">
                      0 charges found
                    </p>
                  </div>

                  {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {selectedCharges.length > 0 && (
                      <button
                        type="button"
                        onClick={
                          () => setPicking(true)
                          // pickingslip()
                        }
                        className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Invoice Charges
                      </button>
                    )}
                    {selectedCharges.length == 0 && (
                      <button
                        type="button"
                        className="block rounded-md bg-slate-100 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-slate-300 shadow-sm "
                      >
                        Select Charges
                      </button>
                    )}
                  </div> */}

                  <div className="ml-4 grid justify-items-end">
                    <button
                      type="submit"
                      onClick={
                        () => setOpen(true)
                        // pickingslip()
                      }
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add Charge
                    </button>
                  </div>
                </div>

                <form onSubmit={() => fetchCustomerDashboard}>
                  <div className="border px-2 py-2 mt-5">
                    <div className="w-full flex flex-row mt-4 justify-center align-end">
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="customer"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Customer
                          </label>
                          <div className="mt-2">
                            <select
                              id="customer"
                              name="customer"
                              onChange={(e) =>
                                setSearchCustomer(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option></option>
                              {dataCustomers.customers.map((customer) => (
                                <option value={customer.customerID}>
                                  {customer.customerName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="mb-5 mx-2">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="fromDate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              From Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                onChange={(e) =>
                                  setSearchFromDate(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="toDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            To Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="toDate"
                              id="toDate"
                              onChange={(e) => setSearchToDate(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-1/5">
                        {" "}
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="cartonFee"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Carton Fee
                          </label>
                          <div className="mt-2">
                            <input
                              type="checkbox"
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={() =>
                                setSearchCartonFeeCheckbox(
                                  !searchCartonFeeCheckbox
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        {isHovered && (
          <div>
            <div class="fixed top-80 left-80 w-[79%] bg-slate-100 border-4 p-4 flex justify-center">
              <h2 className="text-black">{isHovered ? isHovered : ""}</h2>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Billed;
