import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { sendPostRequest, useGetRequest } from "../../Client";
import { useMutation } from "@tanstack/react-query";

export default function CustomerUsersUpdate(props) {
  let userID = props.userID;

  // updating
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [userLevel, setUserLevel] = useState();
  const [division, setDivision] = useState();
  const [monthlyOrderQuantity, setMonthlyOrderQuantity] = useState();

  const { data } = useGetRequest("user/details/" + props.userID);

  const { status: statusDropdownDivisions, data: dataDropdownDivisions } =
    useGetRequest("inventory/divisions/" + props.customerID, {});

  useEffect(() => {
    setFirstName(data?.data?.firstName);
    setLastName(data?.data?.lastName);
    setEmail(data?.data?.email);
    setPassword(data?.data?.password);
    setUserLevel(data?.data?.userLevel);
    setDivision(data?.data?.division);
    setMonthlyOrderQuantity(data?.data?.monthlyOrderQuantity);
  }, [data]);

  // updating inventory
  const updateUser = async (path, body) => {
    const res = await sendPostRequest(path, body);
    props.updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateUser(data.path, { userID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName) {
      alert("Please enter a first name");
      return;
    }

    if (!lastName) {
      alert("Please enter a last name");
      return;
    }

    if (!email) {
      alert("Please enter an email address");
      return;
    }

    if (!password) {
      alert("Please enter a password");
      return;
    }

    try {
      const res = await doUpdateMutation({
        path: "user/update",
        body: {
          userID: props.userID,
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          userLevel: userLevel,
          division: division,
          monthlyOrderQuantity: monthlyOrderQuantity,
        },
      });
      props.setEditOpen(false);
    } catch (e) {
      props.errorNotyf();
    }
  };

  if (data?.data?.firstName) {
    return (
      <Transition.Root show={props.editOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setEditOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => props.setEditOpen(false)}
                            ></button>
                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="divide-gray-200">
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    First Name
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={firstName ? firstName : ""}
                                    onChange={(e) => {
                                      setFirstName(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Last Name
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    value={lastName ? lastName : ""}
                                    onChange={(e) => {
                                      setLastName(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Email
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={email ? email : ""}
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Password
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="password"
                                    id="password"
                                    value={password ? password : ""}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="userLevel"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    User Level
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="userLevel"
                                    name="userLevel"
                                    onChange={(e) =>
                                      setUserLevel(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={data?.data.levelID}>
                                      {data?.data.levelName}
                                    </option>
                                    {data?.userLevels.map((level) => (
                                      <option
                                        key={level.levelID}
                                        value={level.levelID}
                                      >
                                        {level.levelName}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="division"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Division
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="division"
                                    name="division"
                                    onChange={(e) =>
                                      setDivision(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    {data?.data?.division === 0 ? (
                                      <option value={0}>All</option>
                                    ) : (
                                      <option value={data?.data.divisionID}>
                                        {data?.data?.divisionName}
                                      </option>
                                    )}

                                    <option value={0}>All</option>
                                    {dataDropdownDivisions?.divisions?.map(
                                      (division) =>
                                        division.divisionName ===
                                        "All" ? null : (
                                          <option
                                            key={division.divisionID}
                                            value={division.divisionID}
                                          >
                                            {division.divisionName}
                                          </option>
                                        )
                                    )}
                                  </select>
                                </td>
                              </tr>


                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="monthlyOrderQuantity"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Monthly Order Quantity
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="monthlyOrderQuantity"
                                    id="monthlyOrderQuantity"
                                    value={monthlyOrderQuantity ? monthlyOrderQuantity : ""}
                                    onChange={(e) => {
                                      setMonthlyOrderQuantity(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>


                            </tbody>
                          </table>
                          <div className="mt-5">
                            <button
                              type="submit"
                              className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                            >
                              Edit User
                            </button>
                          </div>
                        </form>
                      </div>

                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        {/* <div className="mt-5">
                          <button
                            type="submit"
                            className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                          >
                            Edit Item
                          </button>
                        </div> */}

                        {/* <div className="mt-6">
                          <a
                            onClick={async () => {
                              try {
                                await doUpdateMutation({
                                  path: "inventory/update",
                                  body: {
                                    inventoryID: props.productInventoryID,
                                    productCode: productCode,
                                    productDescription: productDescription,
                                    productUnitPrice: productUnitPrice,
                                  },
                                });
                                props.setEditOpen(false);
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                            className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                          >
                            Edit Item
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
